const actions = {

    LOAD_MENUS: 'LOAD_MENUS',
    LOAD_MENUS_SUCCESS: 'LOAD_MENUS_COMPLETE',
    LOAD_MENUS_ERROR: 'LOAD_MENUS_ERROR',
    ADD_MENU: 'ADD_MENU',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_ERROR: 'ADD_MENU_ERROR',

    ADD_BEVERAGES_MENU: 'ADD_BEVERAGES_MENU',
    ADD_BEVERAGES_MENU_SUCCESS: 'ADD_BEVERAGES_MENU_SUCCESS',
    ADD_BEVERAGES_MENU_ERROR: 'ADD_BEVERAGES_MENU_ERROR',

    SAVE_MENU: 'SAVE_MENU',
    SAVE_MENU_SUCCESS: 'SAVE_MENU_SUCCESS',
    SAVE_MENU_ERROR: 'SAVE_MENU_ERROR',
    EDIT_MENU: 'EDIT_MENU',
    EDIT_MENU_SUCCESS: 'EDIT_MENU_SUCCESS',
    EDIT_MENU_ERROR: 'EDIT_MENU_ERROR',
    UPDATE_MENU: 'UPDATE_MENU',
    UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
    UPDATE_MENU_ERROR: 'UPDATE_MENU_ERROR',
    DELETE_MENU: 'DELETE_MENU',
    DELETE_MENU_SUCCESS: 'DELETE_MENU_SUCCESS',
    DELETE_MENU_ERROR: 'DELETE_MENU_ERROR',
    LOAD_MENU_CATEGORIES: 'LOAD_MENU_CATEGORIES',
    LOAD_MENU_CATEGORIES_SUCCESS: 'LOAD_MENU_CATEGORIES_SUCCESS',
    LOAD_MENU_CATEGORIES_ERROR: 'LOAD_MENU_CATEGORIES_ERROR',
    LOAD_MENU_PRODUCTS_FOR_CATEGORY: 'LOAD_MENU_PRODUCTS_FOR_CATEGORY',
    LOAD_MENU_PRODUCTS_FOR_CATEGORY_SUCCESS: 'LOAD_MENU_PRODUCTS_FOR_CATEGORY_SUCCESS',
    LOAD_MENU_PRODUCTS_FOR_CATEGORY_ERROR: 'LOAD_MENU_PRODUCTS_FOR_CATEGORY_ERROR',
    SET_MENU_VISIBILITY: 'SET_MENU_VISIBILITY',
    SET_MENU_VISIBILITY_SUCCESS: 'SET_MENU_VISIBILITY_SUCCESS',
    SET_MENU_VISIBILITY_ERROR: 'SET_MENU_VISIBILITY_ERROR',
    CLOSE_MENU_PRODUCTS_MODAL: 'CLOSE_MENU_PRODUCTS_MODAL',

    LOAD_SINGLE_MENU: 'LOAD_SINGLE_MENU',
    LOAD_SINGLE_MENU_SUCCESS: 'LOAD_SINGLE_MENU_SUCCESS',
    LOAD_SINGLE_MENU_ERROR: 'LOAD_SINGLE_MENU_ERROR',

    loadMenus: () => ({
        type: actions.LOAD_MENUS,
    }),

    loadSingleMenu: (data) => ({
        type: actions.LOAD_SINGLE_MENU,
        payload: data
    }),

    loadMenuCategories: (id) => ({
        type: actions.LOAD_MENU_CATEGORIES,
        id: id,
    }),

    loadMenuProductsForCategory: (data) => ({
        type: actions.LOAD_MENU_PRODUCTS_FOR_CATEGORY,
        id: data
    }),

    addMenu: () => ({
        type: actions.ADD_MENU,
    }),

    saveMenu: (data) => ({
        type: actions.SAVE_MENU,
        payload: data
    }),

    addBeveragesMenu: (data) => ({
        type: actions.ADD_BEVERAGES_MENU,
        payload: data
    }),

    editMenu: (data) => ({
        type: actions.EDIT_MENU,
        payload: data,
    }),

    updateMenu: (data) => ({
        type: actions.UPDATE_MENU,
        payload: data,
    }),

    setMenuVisibility: (data) => ({
        type: actions.SET_MENU_VISIBILITY,
        payload: data
    }),

    deleteMenu: (id) => ({
        type: actions.DELETE_MENU,
        id: id,
    }),

    closeMenuProductsModal: () => {
        return {
            type: actions.CLOSE_MENU_PRODUCTS_MODAL,
        }
    },
};

export default actions;





