import actions from './actions';

const initState = {};

export default function allergensReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_ALLERGENS_SUCCESS:
            return {...state, allergens: action.payload.value};
        default:
            return state;
    }
}
