import {combineReducers} from 'redux';
import App from '@src/redux/app/reducer';
import Auth from '@src/redux/auth/reducer';
import ThemeSwitcher from '@src/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@src/redux/languageSwitcher/reducer';
import CRUD from '@src/redux/crud/reducer';
import multiUser from '@src/redux/multiUser/reducer'
import Ingredients from '@src/redux/ingredients/reducer'
import Products from '@src/redux/products/reducer'
import Allergens from '@src/redux/allergens/reducer'
import Indications from '@src/redux/indications/reducer'
import Menus from '@src/redux/menus/reducer'
import UserSettings from '@src/redux/userSettings/reducer'
import Staff from '@src/redux/staff/reducer'
import Tables from '@src/redux/tables/reducer'


export default combineReducers({
    Auth,
    App,
    ThemeSwitcher,
    LanguageSwitcher,
    CRUD,
    multiUser,
    Ingredients,
    Products,
    Allergens,
    Indications,
    Menus,
    UserSettings,
    Staff,
    Tables,
});
