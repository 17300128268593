const actions = {

    LOAD_INGREDIENTS: 'LOAD_INGREDIENTS',
    LOAD_INGREDIENTS_SUCCESS: 'LOAD_INGREDIENTS_COMPLETE',
    LOAD_INGREDIENTS_ERROR: 'LOAD_INGREDIENTS_ERROR',
    ADD_INGREDIENT: 'ADD_INGREDIENT',
    ADD_INGREDIENT_SUCCESS: 'ADD_INGREDIENT_SUCCESS',
    ADD_INGREDIENT_ERROR: 'ADD_INGREDIENT_ERROR',
    DELETE_INGREDIENT: 'DELETE_INGREDIENT',
    DELETE_INGREDIENT_SUCCESS: 'DELETE_INGREDIENT_SUCCESS',
    DELETE_INGREDIENT_ERROR: 'DELETE_INGREDIENT_ERROR',

    loadIngredients: () => ({
        type: actions.LOAD_INGREDIENTS,
    }),

    addIngredient: (data) => ({
        type: actions.ADD_INGREDIENT,
        payload: data,
    }),

    deleteIngredient: (data) => ({
        type: actions.DELETE_INGREDIENT,
        payload: data,
    }),
};

export default actions;





