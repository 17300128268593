import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* loadIngredients() {
    yield takeEvery(actions.LOAD_INGREDIENTS, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.ingredients, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_INGREDIENTS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_INGREDIENTS_ERROR,
                payload: error
            })
        }
    })
}

export function* addIngredient() {
    yield takeEvery(actions.ADD_INGREDIENT, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.ingredients, data.payload, true);

        if (response) {
            yield put({
                type: actions.ADD_INGREDIENT_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.ADD_INGREDIENT_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteIngredient() {
    yield takeEvery(actions.DELETE_INGREDIENT, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.ingredients + "/" + data.payload.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_INGREDIENT_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_INGREDIENT_ERROR,
                payload: error
            })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(loadIngredients),
        fork(addIngredient),
        fork(deleteIngredient),
    ]);
}
