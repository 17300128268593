import actions from './actions';
import createNotification from "@src/components/Notification";


const initState = {companyList: []};

export default function userSettingsReducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_COMPANY_DATA_SUCCESS:
            return {
                ...state,
                company: action.payload.value
            }
        case actions.UPDATE_COMPANY_DATA_SUCCESS:
            createNotification("info", "Aggiornamento avvenuto con successo")
            return {
                ...state,
                company: action.payload.value
            }
        default:
            return state;
    }
}
