import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "@src/config/site.config";

export function* loadAllergens() {
    yield takeEvery(actions.LOAD_ALLERGENS, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.allergens, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_ALLERGENS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_ALLERGENS_ERROR,
                payload: error
            })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(loadAllergens),
    ]);
}
