const actions = {

    LOAD_ALLERGENS: 'LOAD_ALLERGENS',
    LOAD_ALLERGENS_SUCCESS: 'LOAD_ALLERGENS_COMPLETE',
    LOAD_ALLERGENS_ERROR: 'LOAD_ALLERGENS_ERROR',


    loadAllergens: () => ({
        type: actions.LOAD_ALLERGENS,
    }),

};

export default actions;





