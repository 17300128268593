import actions from './actions';
import notification from "@src/components/uielements/notification";

const initState = {
    updateElements: false,
    saveOrUpdateOk: false,
    showAddProductModal: false
};

export default function productsReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_PRODUCTS:
            return {
                ...state,
                updateElements: false,
                showAddProductModal: false,
            };
        case actions.LOAD_SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                singleProduct: action.payload.value,
            };
        case actions.LOAD_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload.value,
                updateCategoryBullet: true,
                showAddProductModal: false,
                updateElements: false
            };
        case actions.SHOW_NEW_PRODUCT_MODAL:
            return {
                ...state,
                showAddProductModal: action.value
            };
        case actions.LOAD_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.value,
                showAddProductModal: false,
                updateElements: false
            };
        case actions.SAVE_PRODUCT_SUCCESS:
            notification('info', 'Prodotto inserito', '');
            return {
                ...state,
                updateElements: true,
                saveOrUpdateOk: true,
                showAddProductModal: false
            };
        case actions.SET_PRODUCT_OUT_OF_STOCK_SUCCESS:
            state.products.map(product => {
                if (product.id === action.payload.value.id)
                    product.active = action.payload.value.active
            });
            return {
                ...state,
                products: [...state.products]
            };
        case actions.UPDATE_PRODUCT_SUCCESS:
            notification('info', 'Prodotto aggiornato', '');
            return {
                ...state,
                updateElements: true,
                saveOrUpdateOk: true
            };
        case actions.ADD_CATEGORY_SUCCESS:
            notification('info', 'Categoria aggiunta', '');
            return {
                ...state,
                categories: [...state.categories, action.payload.value],
                updateElements: true
            };
        case actions.DELETE_PRODUCT_SUCCESS:
            notification('info', 'Prodotto eliminato', '');
            return {
                updateElements: true
            };
        case actions.UPDATE_CATEGORY_SUCCESS:
            notification('info', 'Categoria aggiornata', '');
            return {
                ...state,
                updateElements: true
            };
        case actions.DELETE_CATEGORY_SUCCESS:
            notification('info', 'Categoria eliminata', '');
            return {
                ...state,
                updateElements: true
            };
        default:
            return state;
    }
}
