const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    USER_HAS_MORE_TENANTS: 'USER_HAS_MORE_TENANTS',

    SU_TENANT_LOGIN_REQUEST: 'SU_TENANT_LOGIN_REQUEST',
    SU_TENANT_LOGIN_SUCCESS: 'SU_TENANT_LOGIN_SUCCESS',
    SU_TENANT_LOGIN_ERROR: 'SU_TENANT_LOGIN_ERROR',

    SU_LOGIN_SUCCESS: 'SU_LOGIN_SUCCESS',
    SU_LOGIN_ERROR: 'SU_LOGIN_ERROR',

    SIGNUP_REQUEST: 'SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_ERROR: 'SIGNUP_ERROR',

    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_ERROR: 'RECOVER_PASSWORD_ERROR',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',


    REFRESH_TOKEN: 'REFRESH_TOKEN',

    checkAuthorization: () => ({type: actions.CHECK_AUTHORIZATION}),
    login: (data) => ({
        type: actions.LOGIN_REQUEST,
        payload: data
    }),

    suLogin: (data) => ({
        type: actions.SU_TENANT_LOGIN_REQUEST,
        payload: data
    }),

    logout: () => ({
        type: actions.LOGOUT,
    }),


    refreshToken: () => ({
        type: actions.REFRESH_TOKEN,
    }),

    signUp: (data) => ({
        type: actions.SIGNUP_REQUEST,
        payload: data,
    }),

    recoverPassword: (data) => ({
        type: actions.RECOVER_PASSWORD,
        payload: data,
    }),

    resetPassword: (data) => ({
        type: actions.RESET_PASSWORD,
        payload: data,
    })

};
export default actions;
