import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* getCompanyData() {
    yield takeEvery(actions.GET_COMPANY_DATA, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.company, null, true);

        if (response) {

            yield put({
                type: actions.GET_COMPANY_DATA_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.GET_COMPANY_DATA_ERROR,
                payload: error
            })
        }
    })
}

export function* updateCompanyData() {
    yield takeEvery(actions.UPDATE_COMPANY_DATA, function* (data) {


        const {response, error} = yield call(SuperFetch.put, endpoints.companies + "/" + data.payload.id, data.payload, true, true);

        if (response) {
            yield put({
                type: actions.UPDATE_COMPANY_DATA_SUCCESS,
                payload: response
            });
        } else {

            yield put({
                type: actions.UPDATE_COMPANY_DATA_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteUser() {
    yield takeEvery(actions.DELETE_USER, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.companies + "/" + data.payload.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_USER_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_USER_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteUserSuccess() {
    yield takeEvery(actions.DELETE_USER_SUCCESS, function* () {
        localStorage.clear();
    });
}

export default function* rootSaga() {
    yield all([
        fork(getCompanyData),
        fork(updateCompanyData),
        fork(deleteUser),
        fork(deleteUserSuccess)
    ]);
}
