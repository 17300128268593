import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

export const Roles = {
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ADMIN: "ROLE_ADMIN",
    USER: "ROLE_USER",
    WAITER: "ROLE_WAITER",
    CHEF: "ROLE_CHEF",
};


class AuthHelper {
    login = async userInfo => {
        if (!userInfo.username || !userInfo.password) {
            return {error: 'please fill in the input'};
        }
        return await SuperFetch.post('login', userInfo).then(response => {
            return this.checkExpirity(response.token);
        });
    };

    checkExpirity = token => {
        if (!token) {
            return {
                error: 'not matched',
            };
        }
        try {
            const profile = jwtDecode(token);

            const expiredAt = profile.expiredAt || profile.exp * 1000;

            if (expiredAt > new Date().getTime()) {
                return {
                    ...profile,
                    token,
                    expiredAt: new Date(expiredAt),
                };
            } else {
                return {error: 'Token expired'};
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    getCurrentUserId() {
        return localStorage.getItem('id')
    }

    getUsername() {
        return localStorage.getItem('username')
    }

    getRoles() {
        return localStorage.getItem('roles')
    }

    getMainRole(rolesString) {
        if (rolesString) {
            let roles = rolesString.split(',');
            if (roles.filter(role => role === Roles.SUPER_ADMIN).length > 0) {
                return Roles.SUPER_ADMIN
            } else if (roles.filter(role => role === Roles.ADMIN).length > 0) {
                return Roles.ADMIN
            } else if (roles.filter(role => role === Roles.USER).length > 0) {
                return Roles.USER
            } else if (roles.filter(role => role === Roles.WAITER).length > 0) {
                return Roles.WAITER
            } else if (roles.filter(role => role === Roles.CHEF).length > 0) {
                return Roles.CHEF
            }
        }
        return undefined
    }

    getStringRole(roleString) {
        if (roleString) {
            return roleString.replace(/ROLE_/g, "").toLocaleLowerCase().replace(/_/g, " ")
        }
    }

}

export default new AuthHelper();
