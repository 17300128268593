import {all} from 'redux-saga/effects';
import authSaga from '@src/redux/auth/saga';
import crudSagas from '@src/redux/crud/saga';
import multiUserSagas from '@src/redux/multiUser/saga';
import ingredientSaga from '@src/redux/ingredients/saga';
import productSaga from '@src/redux/products/saga';
import allergensSaga from '@src/redux/allergens/saga'
import indicationsSaga from '@src/redux/indications/saga'
import menusSaga from '@src/redux/menus/saga'
import userSettingsSaga from '@src/redux/userSettings/saga'
import staffSaga from '@src/redux/staff/saga'
import tablesSaga from '@src/redux/tables/saga'

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        crudSagas(),
        multiUserSagas(),
        ingredientSaga(),
        productSaga(),
        allergensSaga(),
        indicationsSaga(),
        menusSaga(),
        userSettingsSaga(),
        staffSaga(),
        tablesSaga(),
    ]);
}
