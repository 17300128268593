import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "../../library/helpers/superFetch.js";
import {endpoints} from "../../config/site.config";
import appAction from "../app/actions";


export function* loadEntitities() {
    yield takeEvery(actions.LOAD_ENTITIES, function*(data) {
        if(data.withoutLoader!==true) {
            yield put({type: appAction.LOADING_TRUE});
        }
        let crudUrl;
        let jsonBody = {};

        if(data.isServerPaginationActive){
            jsonBody.limit = data.limit
            jsonBody.offset = data.offset
        } else {
            jsonBody.limit = 100000000
            jsonBody.offset = 0
        }

        if (data.searchFilter) {
            jsonBody.searchFilter = data.searchFilter
        }
        if (data.customUIClassName) {
            jsonBody.customUIClassName = data.customUIClassName
        }
        if (data.customEndpoint) {
            crudUrl = data.customEndpoint
        } else {
            crudUrl = endpoints.crud
        }
          jsonBody.domainClass = data.entityToLoad

        const {response, error} = yield call(SuperFetch.post, crudUrl, jsonBody, true);

        if (response && response.errorType === 0) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.LOAD_ENTITIES_COMPLETE,
                loadedEntity: data.entityToLoad,
                payload: response,
                reduxNamespace: data.reduxNamespace
            })
        } else {

            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.LOAD_ENTITIES_ERROR,
                payload: error
            })
        }
    })
}

export function* loadEntity() {
    yield takeEvery(actions.LOAD_ENTITY, function*(data) {

        let crudUrl = endpoints.crud + "/show/" + data.id +"?domainClass=" + data.entityToLoad;
        let jsonBody = {};
        if (data.customUIClassName) {
            jsonBody.customUIClassName = data.customUIClassName
        }
        if (data.customEndpoint) {
            crudUrl = data.customEndpoint
        }
        if(data.customGetFormEndpoint){
            crudUrl= data.customGetFormEndpoint + "?id="+ data.id;
        }

        const {response, error} = yield call(SuperFetch.post, crudUrl, jsonBody, true);


        if (response) {
            yield put({
                type: actions.LOAD_ENTITY_COMPLETE,
                loadedEntity: data.entityToLoad,
                payload: response,

            })
        } else {
            yield put({
                type: actions.LOAD_ENTITY_ERROR,
                payload: error
            })
        }
    })
}

export function* saveEntity() {

    yield takeEvery(actions.SAVE_ENTITY, function*(data) {
        let crudUrl;
        if (data.customEndpoint) {
            crudUrl = data.customEndpoint
        } else {
            crudUrl = endpoints.crud + "/save?domainClass=" + data.entityToSave
        }


        const {response, error} = yield call(SuperFetch.post, crudUrl, data.entity, true , data.postRequestWithFormData);
        if (response) {

            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_ENTITY_COMPLETE,
                savedEntity: data.entityToSave,
                payload: response
            });

        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_ENTITY_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteEntity() {
    yield takeEvery(actions.DELETE_ENTITY, function*(data) {
        let crudUrl;
        let payload = null

        if (data.customEndpoint) {
            crudUrl = data.customEndpoint
            payload = {id : data.id}
        } else {
            crudUrl = endpoints.crud + "/delete?domainClass=" + data.entityToDelete + "&id=" + data.id
        }

        const {response, error} = yield call((payload === null) ? SuperFetch.get : SuperFetch.post, crudUrl, payload, true);
        if (response) {
            yield put({
                type: actions.DELETE_ENTITY_COMPLETE,
                deletedEntity: data.entityToDelete,
                payload: response
            })
        } else {
            yield put({
                type: actions.DELETE_ENTITY_ERROR,
                payload: error
            })
        }
    })
}

export function* enableDisableToggle() {
    yield takeEvery(actions.ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT, function*(data) {
        const {response, error} = yield call(SuperFetch.post, data.endpoint, data.payload, true);
        if (response) {
            yield put({
                type: actions.ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_SUCCESS,
                payload: data.payload
            })
        } else {
            yield put({
                type: actions.ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_ERROR,
                payload: error
            })
        }
    })
}

/*function getFilterBySearchParams (searchParams) {

    if (searchParams && searchParams.referenceEntityAttribute && searchParams.referenceEntityId && searchParams.referenceEntityAttribute === "organizationId") {
        let filter = {}
        filter[searchParams.referenceEntityAttribute] = [{
            operator: "eq",
            values: [searchParams.referenceEntityId]
        }]
        return filter
    } else if (searchParams && searchParams.referenceEntityAttribute && searchParams.referenceEntityId) {
        let filter = {}
        filter[searchParams.referenceEntityAttribute] = [{
            operator: "idEq",
            values: [searchParams.referenceEntityId]
        }]
        return filter
    }

    if (searchParams && searchParams.flowBoxPrefix) {

        let filter = {
            hwId: [
                {
                    operator: "ilike",
                    values: [searchParams.flowBoxPrefix + "%"]
                }
            ]
        }
        return filter
    }

    return null

}
*/


export default function* rootSaga() {
    yield all([
        fork(loadEntitities),
        fork(loadEntity),
        fork(saveEntity),
        fork(deleteEntity),
        fork(enableDisableToggle),
    ]);
}
