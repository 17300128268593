const actions = {

    LOAD_STAFF: 'LOAD_STAFF',
    LOAD_STAFF_SUCCESS: 'LOAD_STAFF_COMPLETE',
    LOAD_STAFF_ERROR: 'LOAD_STAFF_ERROR',

    LOAD_SINGLE_STAFF: 'LOAD_SINGLE_STAFF',
    LOAD_SINGLE_STAFF_SUCCESS: 'LOAD_SINGLE_STAFF_SUCCESS',
    LOAD_SINGLE_STAFF_ERROR: 'LOAD_SINGLE_STAFF_ERROR',

    SAVE_STAFF: 'SAVE_STAFF',
    SAVE_STAFF_SUCCESS: 'SAVE_STAFF_SUCCESS',
    SAVE_STAFF_ERROR: 'SAVE_STAFF_ERROR',

    UPDATE_STAFF: 'UPDATE_STAFF',
    UPDATE_STAFF_SUCCESS: 'UPDATE_STAFF_SUCCESS',
    UPDATE_STAFF_ERROR: 'UPDATE_STAFF_ERROR',

    DELETE_STAFF: 'DELETE_STAFF',
    DELETE_STAFF_SUCCESS: 'DELETE_STAFF_SUCCESS',
    DELETE_STAFF_ERROR: 'DELETE_STAFF_ERROR',

    LOAD_CATEGORIES: 'LOAD_CATEGORIES',
    LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_SUCCESS',
    LOAD_CATEGORIES_ERROR: 'LOAD_CATEGORIES_ERROR',

    loadStaff: () => ({
        type: actions.LOAD_STAFF,
    }),

    loadSingleStaff: (data) => ({
        type: actions.LOAD_SINGLE_STAFF,
        payload: data
    }),

    loadCategories: () => ({
        type: actions.LOAD_CATEGORIES,
    }),

    saveStaff: (data) => ({
        type: actions.SAVE_STAFF,
        payload: data
    }),

    updateStaff: (data) => ({
        type: actions.UPDATE_STAFF,
        payload: data,
    }),

    deleteStaff: (id) => ({
        type: actions.DELETE_STAFF,
        id: id,
    }),
};

export default actions;





