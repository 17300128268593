import actions from './actions';
import notification from "@src/components/uielements/notification";

const initState = {
    tables: null,
    singleTable: null,
    saveOrUpdateOk: false,
    updateElements: false,
};

export default function tablesReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_TABLES_SUCCESS:
            return {
                ...state,
                tables: action.payload.value,
                singleTable: null,
                saveOrUpdateOk: false,
                updateElements: false,
            };
        case actions.LOAD_SINGLE_TABLE_SUCCESS:
            return {
                ...state,
                singleTable: action.payload.value,
                updateElements: false,
                tableDeleted: false,
            };
        case actions.ADD_TABLE_SUCCESS:
            return {
                ...state,
                tableId: action.payload.value,
            };
        case actions.PROCESS_ORDER_SUCCESS:
            return {
                ...state,
                updateElements: true
            }
        case actions.LOAD_WAITER_PRODUCTS_SUCCESS:
            return {
                ...state,
                waiterProducts: action.payload.value
            };
        case actions.CHANGE_ORDER_QUANTITY_SUCCESS:
            return {
                ...state,
                updateElements: true
            }
        case actions.ADD_PRODUCT_TO_TABLE_SUCCESS:
            notification('info', 'Prodotto aggiunto', '');
            return {
                ...state,
                updateElements: true
            }
        case actions.CANCEL_TABLE_SUCCESS:
            notification('info', 'Tavolo annullato', '');
            return {
                ...state,
                updateElements: false,
                tableDeleted: true
            }
        case actions.CLOSE_TABLE_SUCCESS:
            notification('info', 'Tavolo chiuso', '');
            return {
                ...state,
                updateElements: true
            }
        case actions.CONFIRM_TABLE_SUCCESS:
            notification('info', 'Tavolo confermato', '');
            return {
                ...state,
                updateElements: false,
                tableConfirmed: true
            }
        default:
            return state;
    }
}
