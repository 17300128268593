const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault',
    A4GTheme: window.A4GTheme,
    A4GPrimaryColor: "#394049",
    A4GBlue: "#17A4CC",
    A4GBlueOpaque: "#1285A5",
    SIOPrimaryColor: "#0977CB"
};


let apiUrl = window.endpoints.apiUrl

const endpoints = {
    login: apiUrl + "/restLogin",
    logout: apiUrl + "/restLogout",
    crud: apiUrl + "/crud",
    //worker order
    searchUser: apiUrl + "/user/searchUser",
    getImageResource: apiUrl + "/file/getImageResource",
    refreshToken: apiUrl + "/oauth/access_token",
    getTenantInfos: apiUrl + "/user/getInfo",
    getImageFromText: apiUrl + "/file/getImageFromText",

    companies: apiUrl + "/companies",
    company: apiUrl + "/company/current",

    //endopoints per la registrazione
    registration: apiUrl + "/registration",
    registerCompany: "/registerCompany",
    resetPasswordByEmail: "/resetPasswordByEmail",
    resetPassword: "/resetPassword",

    ingredients: apiUrl + "/ingredients",
    categories: apiUrl + "/categories",
    products: apiUrl + "/products",
    product: apiUrl + "/product",
    menus: apiUrl + "/menus",
    staff: apiUrl + "/workers",

    tables: apiUrl + "/tables",
    table: apiUrl + "/table",

    allergens: apiUrl + "/allergens",
    indications: apiUrl + "/indications",
};

export {endpoints, themeConfig}
