import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "@src/config/site.config";

export function* loadProducts() {

    yield takeEvery(actions.LOAD_PRODUCTS, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.products, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_PRODUCTS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_PRODUCTS_ERROR,
                payload: error
            })
        }
    })
}

export function* loadSingleProduct() {
    yield takeEvery(actions.LOAD_SINGLE_PRODUCT, function* (data) {

        if (data && data.payload && data.payload !== 'undefined') {
            const {response, error} = yield call(SuperFetch.get, endpoints.products + "/" + data.payload, null, true);
            if (response) {
                yield put({
                    type: actions.LOAD_SINGLE_PRODUCT_SUCCESS,
                    payload: response
                });
            } else {
                yield put({
                    type: actions.LOAD_SINGLE_PRODUCT_ERROR,
                    payload: error
                })
            }
        } else {
            yield put({
                type: actions.LOAD_SINGLE_PRODUCT_SUCCESS,
                payload: {value: null}
            });
        }
    })
}

export function* saveProduct() {
    yield takeEvery(actions.SAVE_PRODUCT, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.products, data.payload, true, true);

        if (response) {
            yield put({
                type: actions.SAVE_PRODUCT_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SAVE_PRODUCT_ERROR,
                payload: error
            })
        }
    })
}

export function* updateProduct() {
    yield takeEvery(actions.UPDATE_PRODUCT, function* (data) {

        const {response, error} = yield call(SuperFetch.put, endpoints.products + "/" + data.payload.id, data.payload, true, true);

        if (response) {
            yield put({
                type: actions.UPDATE_PRODUCT_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.UPDATE_PRODUCT_ERROR,
                payload: error
            })
        }
    })
}

export function* setProductOutOfStock() {
    yield takeEvery(actions.SET_PRODUCT_OUT_OF_STOCK, function* (data) {

        const {response, error} = yield call(SuperFetch.PATCH, endpoints.products + "/" + data.payload.id, {active: data.payload.active}, true);

        if (response) {
            yield put({
                type: actions.SET_PRODUCT_OUT_OF_STOCK_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SET_PRODUCT_OUT_OF_STOCK_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteProduct() {
    yield takeEvery(actions.DELETE_PRODUCT, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.products + "/" + data.payload.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_PRODUCT_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_PRODUCT_ERROR,
                payload: error
            })
        }
    })
}


export function* loadCategories() {
    yield takeEvery(actions.LOAD_CATEGORIES, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.categories, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_CATEGORIES_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_CATEGORIES_ERROR,
                payload: error
            })
        }
    })
}

export function* addCategory() {
    yield takeEvery(actions.ADD_CATEGORY, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.categories, data.payload, true);

        if (response) {
            yield put({
                type: actions.ADD_CATEGORY_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.ADD_CATEGORY_ERROR,
                payload: error
            })
        }
    })
}

export function* updateCategory() {
    yield takeEvery(actions.UPDATE_CATEGORY, function* (data) {

        const {response, error} = yield call(SuperFetch.put, endpoints.categories + "/" + data.payload.id, data.payload, true);

        if (response) {
            yield put({
                type: actions.UPDATE_CATEGORY_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.UPDATE_CATEGORY_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteCategory() {
    yield takeEvery(actions.DELETE_CATEGORY, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.categories + "/" + data.payload.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_CATEGORY_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_CATEGORY_ERROR,
                payload: error
            })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(loadProducts),
        fork(setProductOutOfStock),
        fork(saveProduct),
        fork(updateProduct),
        fork(deleteProduct),
        fork(loadCategories),
        fork(addCategory),
        fork(deleteCategory),
        fork(updateCategory),
        fork(loadSingleProduct),
    ]);
}
