import createNotification from "../../components/Notification";
import LanguageHelper from "./languageHelper";
import {isTokenExpired} from "./utility";
import {endpoints} from "../../config/site.config";
import {objectToFormData} from '@src/library/utils/objectToFormData';

// const headerType= {
//   base: 1,
//   authenticate: 2,
//   file: 3
// }

const customHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const fileHeader = () => ({
    Accept: 'multipart/form-data',
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});


const baseHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json'
})

const parseValue = (valueToParse) => {
    if (valueToParse instanceof Array) {
        if (valueToParse[0] instanceof Object) {
            let asd = []
            valueToParse.forEach(value => {
                asd.push(JSON.stringify(value))
            })
            return (asd)
        } else {
            return valueToParse.toString()
        }
    } else if (valueToParse instanceof Object) {
        return JSON.stringify(valueToParse)
    } else
        return valueToParse ? valueToParse.toString() : ""


}

const base = (method, url, data = {}, authenticate = false, postRequestWithFormData = false, errorTypesToNotNotify, isFile = false) => {

    let requestConfig = {
        method,
        credentials: 'include'
    };
    if (authenticate) requestConfig.headers = customHeader();
    else requestConfig.headers = baseHeader();
    if (data) {
        if (postRequestWithFormData) {

            requestConfig.body = objectToFormData(data, {indices: true});
            requestConfig.headers = fileHeader()
        } else {
            requestConfig.body = JSON.stringify(data)
        }
        /*if(isFile){
            requestConfig.headers = fileDownloadHeader();
        }*/
    }

    if (authenticate && isTokenExpired()) {
        return fetch(endpoints.refreshToken, requestConfig)
            .then(response => response.json())
            .then(json => {
                localStorage.setItem("id_token", json.access_token)
                localStorage.setItem("expires_in", json.expires_in)
                localStorage.setItem("timestamp", new Date().getTime().toString())

                if (json.access_token != null) {
                    return _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig)
                } else {
                    return {}
                }
            })
            .catch(error => ({
                error: error
            }));
    } else {
        return _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig)
    }

};

function _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig) {
    return fetch(url, requestConfig)
        .then(response => {
            if (!isFile) {
                return handleResponse(response, errorTypesToNotNotify)
            } else {
                const link = document.createElement('a');
                // Browsers that support HTML5 download attribute
                if (link.download !== undefined) {
                    link.setAttribute('href', url);
                    //link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                return {}
            }
        })
        .catch(error => ({
            error: error
        }));
}

async function handleResponse(response, errorTypesToNotNotify) {
    let errorMessage;
    let json;
    if (response.status === 401) {
        createNotification("error", LanguageHelper.formatMessage({id: "serverResponse.401"}))
    } else if (response && response.status && (response.status !== 200 && response.status !== 206)) {
        json = await response.json();
        if (response.status) {
            errorMessage = "An error has occurred. Error code: " + response.status;
            if (response.statusText) {
                errorMessage += "\nError message: " + errorMessage
            }
        }
    } else {
        if (response.status === 206) {
            json = response;
        } else {
            json = await response.json();
            if (json && json.errorType && json.errorType !== 0) {
                if (json.message) {
                    errorMessage = json.message
                } else {
                    errorMessage = "An error has occurred"
                }
            }
        }
    }


    if (errorMessage) {


        let errors = json && json.value && json.value.errors ? json.value.errors : null
        let errorResponse = {error: errorMessage, value: errors}
        //if an error type is provided by the backend, check if this error type has to be not notified
        if (json && json.errorType) {
            errorResponse.errorType = json.errorType

            if (!(errorTypesToNotNotify && errorTypesToNotNotify.indexOf(json.errorType) !== -1)) {

                if (errors && errors.length > 0) {
                    errors.map(e => createNotification('error', e.message)
                    )
                } else {
                    createNotification('error', errorMessage);
                }
            }
        } else {
            if (errors && errors.length > 0) {
                errors.map(e => createNotification('error', e.message)
                )
            } else {
                createNotification('error', errorMessage);
            }
        }
        return errorResponse
    } else {
        return {response: json}
    }
}

const SuperFetch = {};
['get', 'post', 'put', 'delete', 'PATCH'].forEach(method => {
    SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
