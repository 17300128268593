import actions from './actions';
import notification from "@src/components/uielements/notification";

const initState = {
    staff: null,
    singleStaff: null,
    saveOrUpdateOk: false,
};

export default function staffReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_STAFF_SUCCESS:
            return {
                ...state,
                staff: action.payload.value,
                singleStaff: null,
                saveOrUpdateOk: false,
            };
        case actions.LOAD_SINGLE_STAFF_SUCCESS:
            return {
                ...state,
                singleStaff: action.payload.value,
            };
        case actions.LOAD_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.value
            };
        case actions.SAVE_STAFF_SUCCESS:

            notification('info', 'Personale di sala inserito', '');
            return {
                ...state,
                singleStaff: null,
                saveOrUpdateOk: true,
            };
        case actions.UPDATE_STAFF_SUCCESS:
            notification('info', 'Personale di sala aggiornato', '');
            return {
                ...state,
                singleStaff: null,
                saveOrUpdateOk: true,
            };
        case actions.DELETE_STAFF_SUCCESS:
            notification('info', 'Personale di sala eliminato', '');
            const newList = [];
            state.staff.map(staff => {
                if (staff.id !== action.payload.value)
                    newList.push(staff);
            });
            return {
                ...state,
                staff: newList,
            };
        default:
            return state;
    }
}
