import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* loadMenus() {
    yield takeEvery(actions.LOAD_MENUS, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.menus, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_MENUS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_MENUS_ERROR,
                payload: error
            })
        }
    })
}

export function* saveMenu() {
    yield takeEvery(actions.SAVE_MENU, function* (data) {


        const {response, error} = yield call(SuperFetch.post, endpoints.menus, data.payload, true);

        if (response) {
            yield put({
                type: actions.SAVE_MENU_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SAVE_MENU_ERROR,
                payload: error
            })
        }
    })
}

export function* addBeveragesMenu() {
    yield takeEvery(actions.ADD_BEVERAGES_MENU, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.menus, data.payload, true);

        if (response) {
            yield put({
                type: actions.SAVE_MENU_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SAVE_MENU_ERROR,
                payload: error
            })
        }
    })
}

export function* updateMenu() {
    yield takeEvery(actions.UPDATE_MENU, function* (data) {


        const {response, error} = yield call(SuperFetch.put, endpoints.menus + "/" + data.payload.id, data.payload, true, true);

        if (response) {
            yield put({
                type: actions.UPDATE_MENU_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.UPDATE_MENU_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteMenu() {
    yield takeEvery(actions.DELETE_MENU, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.menus + "/" + data.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_MENU_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_MENU_ERROR,
                payload: error
            })
        }
    })
}

export function* setMenuVisibility() {
    yield takeEvery(actions.SET_MENU_VISIBILITY, function* (data) {

        const {response, error} = yield call(SuperFetch.PATCH, endpoints.menus + "/" + data.payload.id, {active: data.payload.event}, true);

        if (response) {
            yield put({
                type: actions.SET_MENU_VISIBILITY_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SET_MENU_VISIBILITY_ERROR,
                payload: error
            })
        }
    })
}


export function* loadMenuCategories() {
    yield takeEvery(actions.LOAD_MENU_CATEGORIES, function* () {

        const {response, error} = yield call(SuperFetch.get, endpoints.categories, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_MENU_CATEGORIES_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_MENU_CATEGORIES_ERROR,
                payload: error
            })
        }
    })
}

export function* loadMenuProductsForCategory() {
    yield takeEvery(actions.LOAD_MENU_PRODUCTS_FOR_CATEGORY, function* (data) {

        let endpoint = endpoints.categories + "/" + data.id + "/products"

        const {response, error} = yield call(SuperFetch.get, endpoint, null, true)

        if (response) {
            yield put({
                type: actions.LOAD_MENU_PRODUCTS_FOR_CATEGORY_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_MENU_PRODUCTS_FOR_CATEGORY_ERROR,
                payload: error
            })
        }
    })
}

export function* loadSingleMenu() {
    yield takeEvery(actions.LOAD_SINGLE_MENU, function* (data) {

        if (data && data.payload && data.payload !== 'undefined') {
            const {response, error} = yield call(SuperFetch.get, endpoints.menus + "/" + data.payload, null, true);
            if (response) {
                yield put({
                    type: actions.LOAD_SINGLE_MENU_SUCCESS,
                    payload: response
                });
            } else {
                yield put({
                    type: actions.LOAD_SINGLE_MENU_ERROR,
                    payload: error
                })
            }
        } else {
            yield put({
                type: actions.LOAD_SINGLE_MENU_SUCCESS,
                payload: {value: null}
            });
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(loadMenus),
        fork(saveMenu),
        fork(addBeveragesMenu),
        fork(updateMenu),
        fork(deleteMenu),
        fork(setMenuVisibility),
        fork(loadMenuCategories),
        fork(loadMenuProductsForCategory),
        fork(loadSingleMenu)
    ]);
}
