export const PUBLIC_ROUTE = {
    LANDING: '/',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    PASSWORD_RECOVERY: '/passwordrecovery',
    RESET_PASSWORD: '/resetpassword/:oneTimeToken',
    SELECT_TENANT: '/select-tenant',
    PAGE_404: '/404',
    PAGE_500: '/500',
    AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
    DASHBOARD: '',
    BLANKPAGE: 'blankPage',
    CRUDTABLE: 'list/:entityToLoad',
    CRUDADDORMODIFY: 'addOrModify/:entityToLoad',
    INGREDIENTS: "ingredients",
    PRODUCTS: "products",
    PRODUCTADD: "productAddOrModify/:typology",
    PRODUCTMODIFY: "productAddOrModify/:id",
    MENU: "menu",
    MENUADDORMODIFY: 'menuAddOrModify/:id',
    USERSETTINGS: "userSettings",
    STAFF: "staff",
    STAFFADDORMODIFY: 'staffAddOrModify/:id',
    TABLES: 'tables',
    WAITERTABLES: 'waiterTables',
    WAITERSINGLETABLE: 'waiterSingleTable/:id',
    WAITERADDTABLE: 'waiterAddTable',
    RESTAURANTS: 'restaurants',
};
