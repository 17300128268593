const actions = {

    LOAD_INDICATIONS: 'LOAD_INDICATIONS',
    LOAD_INDICATIONS_SUCCESS: 'LOAD_INDICATIONS_COMPLETE',
    LOAD_INDICATIONS_ERROR: 'LOAD_INDICATIONS_ERROR',
    ADD_INDICATION: 'ADD_INDICATION',
    ADD_INDICATION_SUCCESS: 'ADD_INDICATION_SUCCESS',
    ADD_INDICATION_ERROR: 'ADD_INDICATION_ERROR',
    DELETE_INDICATION: 'DELETE_INDICATION',
    DELETE_INDICATION_SUCCESS: 'DELETE_INDICATION_SUCCESS',
    DELETE_INDICATION_ERROR: 'DELETE_INDICATION_ERROR',

    loadIndications: () => ({
        type: actions.LOAD_INDICATIONS,
    }),

    addIndication: (data) => ({
        type: actions.ADD_INDICATION,
        payload: data,
    }),

    deleteIndication: (data) => ({
        type: actions.DELETE_INDICATION,
        payload: data,
    }),
};

export default actions;





