import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* loadIndications() {
    yield takeEvery(actions.LOAD_INDICATIONS, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.indications, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_INDICATIONS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_INDICATIONS_ERROR,
                payload: error
            })
        }
    })
}

export function* addIndication() {
    yield takeEvery(actions.ADD_INDICATION, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.indications, data.payload, true);

        if (response) {
            yield put({
                type: actions.ADD_INDICATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.ADD_INDICATION_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteIndication() {
    yield takeEvery(actions.DELETE_INDICATION, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.indications + "/" + data.payload.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_INDICATION_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_INDICATION_ERROR,
                payload: error
            })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(loadIndications),
        fork(addIndication),
        fork(deleteIndication),
    ]);
}
