import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* getTenantInfos() {
    yield takeEvery(actions.GET_TENANT_INFO, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.getTenantInfos, data.payload, true);
        if (response) {

            yield put({
                type: actions.GET_TENANT_INFO_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SAVE_ENTITY_ERROR,
                payload: error
            })
        }
    })
}

export function* setCompanyVisibility() {
    yield takeEvery(actions.SET_COMPANY_VISIBILITY, function* (data) {


        const {response, error} = yield call(SuperFetch.PATCH, endpoints.companies + "/" + data.payload.id, {visible: data.payload.event}, true);

        if (response) {
            yield put({
                type: actions.SET_COMPANY_VISIBILITY_SUCCESS,
                payload: response
            });
        } else {

            yield put({
                type: actions.SET_COMPANY_VISIBILITY_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getTenantInfos),
        fork(setCompanyVisibility),
    ]);
}
