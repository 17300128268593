import actions from './actions';
import notification from "@src/components/uielements/notification";

const initState = {saveOrUpdateOk: false};

export default function menusReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_MENUS:
            return {
                ...state,
                saveOrUpdateOk: false
            };
        case actions.LOAD_MENUS_SUCCESS:
            return {
                ...state,
                menus: action.payload.value,
                saveOrUpdateOk: false
            };
        case actions.LOAD_SINGLE_MENU_SUCCESS:
            return {
                ...state,
                entityToUpdate: action.payload.value,
            };
        case actions.CLOSE_MENU_PRODUCTS_MODAL:
            return {
                ...state,
                productModalVisibility: false,
                productsForCategory: null,
            };
        case actions.LOAD_MENU_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.value
            };
        case actions.SAVE_MENU_SUCCESS:
            notification('info', 'Menù inserito', '');
            return {
                ...state,
                saveOrUpdateOk: true,
                menus: [...state.menus, action.payload.value],
            };
        case actions.SET_MENU_VISIBILITY_SUCCESS:
            state.menus.map(menu => {
                if (menu.id === action.payload.value.id)
                    menu.active = action.payload.value.active
            });
            return {
                ...state,
                menus: [...state.menus]
            };
        case actions.UPDATE_MENU_SUCCESS:
            notification('info', 'Menù aggiornato', '');
            return {
                ...state,
                saveOrUpdateOk: true
            };
        case actions.DELETE_MENU_SUCCESS:
            notification('info', 'Menù eliminato', '');
            const newList = [];
            state.menus.map(menu => {
                if (menu.id !== action.payload.value)
                    newList.push(menu);
            });
            return {
                ...state,
                menus: newList,
            };
        case actions.LOAD_MENU_PRODUCTS_FOR_CATEGORY_SUCCESS:

            let productsExist = true
            if (action.payload.value.length === 0) {
                notification('info', 'Nessun prodotto per la categoria selezionata', '');
                productsExist = false
            }
            return {
                ...state,
                productsForCategory: action.payload.value,
                productModalVisibility: productsExist
            }
        default:
            return state;
    }
}
