const actions = {

    LOAD_PRODUCTS: 'LOAD_PRODUCTS',
    LOAD_PRODUCTS_SUCCESS: 'LOAD_PRODUCTS_COMPLETE',
    LOAD_PRODUCTS_ERROR: 'LOAD_PRODUCTS_ERROR',
    LOAD_SINGLE_PRODUCT: 'LOAD_SINGLE_PRODUCT',
    LOAD_SINGLE_PRODUCT_SUCCESS: 'LOAD_SINGLE_PRODUCT_SUCCESS',
    LOAD_SINGLE_PRODUCT_ERROR: 'LOAD_SINGLE_PRODUCT_ERROR',
    SAVE_PRODUCT: 'SAVE_PRODUCT',
    SAVE_PRODUCT_SUCCESS: 'SAVE_PRODUCT_SUCCESS',
    SAVE_PRODUCT_ERROR: 'SAVE_PRODUCT_ERROR',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_ERROR: 'UPDATE_PRODUCT_ERROR',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',
    SET_PRODUCT_OUT_OF_STOCK: 'SET_PRODUCT_OUT_OF_STOCK',
    SET_PRODUCT_OUT_OF_STOCK_SUCCESS: 'SET_PRODUCT_OUT_OF_STOCK_SUCCESS',
    SET_PRODUCT_OUT_OF_STOCK_ERROR: 'SET_PRODUCT_OUT_OF_STOCK_ERROR',

    SHOW_NEW_PRODUCT_MODAL: 'SHOW_NEW_PRODUCT_MODAL',

    SHOW_CATEGORIES_MODAL: 'SHOW_CATEGORIES_MODAL',
    LOAD_CATEGORIES: 'LOAD_CATEGORIES',
    LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_COMPLETE',
    LOAD_CATEGORIES_ERROR: 'LOAD_CATEGORIES_ERROR',
    ADD_CATEGORY: 'ADD_CATEGORY',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

    loadProducts: () => ({
        type: actions.LOAD_PRODUCTS,
    }),

    loadSingleProduct: (data) => ({
        type: actions.LOAD_SINGLE_PRODUCT,
        payload: data
    }),

    showNewProductModal: (data) => ({
        type: actions.SHOW_NEW_PRODUCT_MODAL,
        value: data
    }),

    setProductOutOfStock: (data) => ({
        type: actions.SET_PRODUCT_OUT_OF_STOCK,
        payload: data,
    }),

    saveProduct: (data) => ({
        type: actions.SAVE_PRODUCT,
        payload: data,
    }),

    updateProduct: (data) => ({
        type: actions.UPDATE_PRODUCT,
        payload: data,
    }),

    deleteProduct: (data) => ({
        type: actions.DELETE_PRODUCT,
        payload: data,
    }),

    loadCategories: () => ({
        type: actions.LOAD_CATEGORIES,
    }),

    addCategory: (data) => ({
        type: actions.ADD_CATEGORY,
        payload: data,
    }),

    deleteCategory: (data) => ({
        type: actions.DELETE_CATEGORY,
        payload: data,
    }),
    
    updateCategory: (data) => ({
        type: actions.UPDATE_CATEGORY,
        payload: data,
    })
};

export default actions;





