import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* loadStaff() {
    yield takeEvery(actions.LOAD_STAFF, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.staff, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_STAFF_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_STAFF_ERROR,
                payload: error
            })
        }
    })
}

export function* loadSingleStaff() {
    yield takeEvery(actions.LOAD_SINGLE_STAFF, function* (data) {

        if (data && data.payload && data.payload !== 'undefined') {
            const {response, error} = yield call(SuperFetch.get, endpoints.staff + "/" + data.payload, null, true);
            if (response) {
                yield put({
                    type: actions.LOAD_SINGLE_STAFF_SUCCESS,
                    payload: response
                });
            } else {
                yield put({
                    type: actions.LOAD_SINGLE_STAFF_ERROR,
                    payload: error
                })
            }
        } else {
            yield put({
                type: actions.LOAD_SINGLE_STAFF_SUCCESS,
                payload: {value: null}
            });
        }
    })
}

export function* saveStaff() {
    yield takeEvery(actions.SAVE_STAFF, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.registration + "/registerWorker", data.payload, true);

        if (response) {
            yield put({
                type: actions.SAVE_STAFF_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.SAVE_STAFF_ERROR,
                payload: error
            })
        }
    })
}


export function* updateStaff() {
    yield takeEvery(actions.UPDATE_STAFF, function* (data) {


        const {response, error} = yield call(SuperFetch.put, endpoints.staff + "/" + data.payload.id, data.payload, true, true);

        if (response) {
            yield put({
                type: actions.UPDATE_STAFF_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.UPDATE_STAFF_ERROR,
                payload: error
            })
        }
    })
}

export function* deleteStaff() {
    yield takeEvery(actions.DELETE_STAFF, function* (data) {

        const {response, error} = yield call(SuperFetch.delete, endpoints.staff + "/" + data.id, null, true);

        if (response) {
            yield put({
                type: actions.DELETE_STAFF_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.DELETE_STAFF_ERROR,
                payload: error
            })
        }
    })
}


export function* loadCategories() {
    yield takeEvery(actions.LOAD_CATEGORIES, function* () {

        const {response, error} = yield call(SuperFetch.get, endpoints.categories, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_CATEGORIES_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_CATEGORIES_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(loadStaff),
        fork(loadSingleStaff),
        fork(saveStaff),
        fork(updateStaff),
        fork(deleteStaff),
        fork(loadCategories),
    ]);
}
