import actions from './actions';
import notification from "@src/components/uielements/notification";

const initState = {};

export default function indicationsReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_INDICATIONS_SUCCESS:
            return {...state, indications: action.payload.value};
        case actions.ADD_INDICATION_SUCCESS:
            notification('info', 'Indication added', '');
            return {
                ...state,
                indications: [...state.indications, action.payload.value]
            };
        case actions.DELETE_INDICATION_SUCCESS:
            notification('info', 'Indication deleted', '');
            const newList = [];

            state.indications.forEach(indication => {
                if (indication.id !== action.payload.value)
                    newList.push(indication);
            });
            return {
                ...state,
                indications: newList,
            }
        default:
            return state;
    }
}
