import actions from './actions';
import notification from "@src/components/uielements/notification";

const initState = {};

export default function ingredientsReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_INGREDIENTS_SUCCESS:

            return {...state, ingredients: action.payload.value};
        case actions.ADD_INGREDIENT_SUCCESS:
            notification('info', 'Ingrediente aggiunto', '');
            return {
                ...state,
                ingredients: [...state.ingredients, action.payload.value],
            };
        case actions.DELETE_INGREDIENT_SUCCESS:
            notification('info', 'Ingredient deleted', '');
            const newList = [];

            state.ingredients.forEach(ingredient => {
                if (ingredient.id !== action.payload.value)
                    newList.push(ingredient);
            });
            return {
                ...state,
                ingredients: newList,
            }
        default:
            return state;
    }
}
