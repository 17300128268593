import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "../../config/site.config";

export function* loadTables() {
    yield takeEvery(actions.LOAD_TABLES, function* () {
        const {response, error} = yield call(SuperFetch.get, endpoints.tables, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_TABLES_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_TABLES_ERROR,
                payload: error
            })
        }
    })
}

export function* loadSingleTable() {
    yield takeEvery(actions.LOAD_SINGLE_TABLE, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.tables + "/" + data.payload.id, null, true);
        if (response) {
            yield put({
                type: actions.LOAD_SINGLE_TABLE_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_SINGLE_TABLE_ERROR,
                payload: error
            })
        }
    })
}

export function* addTable() {
    yield takeEvery(actions.ADD_TABLE, function* (data) {

        const {response, error} = yield call(SuperFetch.get,
            endpoints.table + "/check"
            + "?code=" + data.payload.code
            + "&name=" + data.payload.name
            + "&number=" + data.payload.number
            , null, true
        );

        if (response) {

            yield put({
                type: actions.ADD_TABLE_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.ADD_TABLE_ERROR,
                payload: error
            })
        }
    })
}

export function* processOrder() {
    yield takeEvery(actions.PROCESS_ORDER, function* (data) {

        const {response, error} = yield call(SuperFetch.PATCH, endpoints.tables + "/" + data.payload.tableId + "/orders/" + data.payload.orderId, {processed: data.payload.event}, true);

        if (response) {
            yield put({
                type: actions.PROCESS_ORDER_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.PROCESS_ORDER_ERROR,
                payload: error
            })
        }
    })
}


export function* loadWaiterProducts() {
    yield takeEvery(actions.LOAD_WAITER_PRODUCTS, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.product + "/search?name=" + data.payload.name, null, true);

        if (response) {
            yield put({
                type: actions.LOAD_WAITER_PRODUCTS_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.LOAD_WAITER_PRODUCTS_ERROR,
                payload: error
            })
        }
    })
}

export function* changeOrderQuantity() {
    yield takeEvery(actions.CHANGE_ORDER_QUANTITY, function* (data) {

        const {response, error} = yield call(SuperFetch.PATCH, endpoints.tables + "/" + data.payload.tableId + "/orders/" + data.payload.orderId, {quantity: data.payload.quantity, menuId: data.payload.menuId, note: data.payload.note}, true);

        if (response) {
            yield put({
                type: actions.CHANGE_ORDER_QUANTITY_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.CHANGE_ORDER_QUANTITY_ERROR,
                payload: error
            })
        }
    })
}

export function* addProductToTable() {
    yield takeEvery(actions.ADD_PRODUCT_TO_TABLE, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.tables + "/" + data.payload.tableId + "/orders", data.payload.entityToAdd, true);

        if (response) {
            yield put({
                type: actions.ADD_PRODUCT_TO_TABLE_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.ADD_PRODUCT_TO_TABLE_ERROR,
                payload: error
            })
        }
    })
}


export function* closeTable() {
    yield takeEvery(actions.CLOSE_TABLE, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.table + "/close", data.payload, true);

        if (response) {
            yield put({
                type: actions.CLOSE_TABLE_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.CLOSE_TABLE_ERROR,
                payload: error
            })
        }
    })
}

export function* cancelTable() {
    yield takeEvery(actions.CANCEL_TABLE, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.table + "/cancel", data.payload, true);

        if (response) {
            yield put({
                type: actions.CANCEL_TABLE_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.CANCEL_TABLE_ERROR,
                payload: error
            })
        }
    })
}

export function* confirmTable() {
    yield takeEvery(actions.CONFIRM_TABLE, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.table + "/waiterConfirm", data.payload, true);

        if (response) {
            yield put({
                type: actions.CONFIRM_TABLE_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.CONFIRM_TABLE_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(loadTables),
        fork(loadSingleTable),
        fork(processOrder),
        fork(addTable),
        fork(loadWaiterProducts),
        fork(changeOrderQuantity),
        fork(addProductToTable),
        fork(closeTable),
        fork(cancelTable),
        fork(confirmTable),
    ]);
}
