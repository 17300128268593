const actions = {

    GET_TENANT_INFO: 'GET_TENANT_INFO',
    GET_TENANT_INFO_SUCCESS: 'GET_TENANT_INFO_SUCCESS',
    GET_TENANT_INFO_ERROR: 'GET_TENANT_INFO_ERROR',

    GET_COMPANY_DATA: 'GET_COMPANY_DATA',
    GET_COMPANY_DATA_SUCCESS: 'GET_COMPANY_DATA_SUCCESS',
    GET_COMPANY_DATA_ERROR: 'GET_COMPANY_DATA_ERROR',

    SET_COMPANY_VISIBILITY: 'SET_COMPANY_VISIBILITY',
    SET_COMPANY_VISIBILITY_SUCCESS: 'SET_COMPANY_VISIBILITY_SUCCESS',
    SET_COMPANY_VISIBILITY_ERROR: 'SET_COMPANY_VISIBILITY_ERROR',

    getTenantInfos: (data) => ({
        type: actions.GET_TENANT_INFO,
        payload: data,
    }),

    setCompanyVisibility: (data) => ({
        type: actions.SET_COMPANY_VISIBILITY,
        payload: data
    }),

};


export default actions;





