const actions = {

    LOAD_TABLES: 'LOAD_TABLES',
    LOAD_TABLES_SUCCESS: 'LOAD_TABLES_COMPLETE',
    LOAD_TABLES_ERROR: 'LOAD_TABLES_ERROR',

    LOAD_SINGLE_TABLE: 'LOAD_SINGLE_TABLES',
    LOAD_SINGLE_TABLE_SUCCESS: 'LOAD_SINGLE_TABLES_SUCCESS',
    LOAD_SINGLE_TABLE_ERROR: 'LOAD_SINGLE_TABLES_ERROR',

    ADD_TABLE: 'ADD_TABLE',
    ADD_TABLE_SUCCESS: 'ADD_TABLE_SUCCESS',
    ADD_TABLE_ERROR: 'ADD_TABLE_ERROR',

    PROCESS_ORDER: 'PROCESS_ORDER',
    PROCESS_ORDER_SUCCESS: 'PROCESS_ORDER_SUCCESS',
    PROCESS_ORDER_ERROR: 'PROCESS_ORDER_ERROR',

    LOAD_WAITER_PRODUCTS: 'LOAD_WAITER_PRODUCTS',
    LOAD_WAITER_PRODUCTS_SUCCESS: 'LOAD_WAITER_PRODUCTS_SUCCESS',
    LOAD_WAITER_PRODUCTS_ERROR: 'LOAD_WAITER_PRODUCTS_ERROR',

    ADD_PRODUCT_TO_TABLE: 'ADD_PRODUCT_TO_TABLE',
    ADD_PRODUCT_TO_TABLE_SUCCESS: 'ADD_PRODUCT_TO_TABLE_SUCCESS',
    ADD_PRODUCT_TO_TABLE_ERROR: 'ADD_PRODUCT_TO_TABLE_ERROR',

    CHANGE_ORDER_QUANTITY: 'CHANGE_ORDER_QUANTITY',
    CHANGE_ORDER_QUANTITY_SUCCESS: 'CHANGE_ORDER_QUANTITY_SUCCESS',
    CHANGE_ORDER_QUANTITY_ERROR: 'CHANGE_ORDER_QUANTITY_ERROR',

    CANCEL_TABLE: 'CANCEL_TABLE',
    CANCEL_TABLE_SUCCESS: 'CANCEL_TABLE_SUCCESS',
    CANCEL_TABLE_ERROR: 'CANCEL_TABLE_ERROR',

    CLOSE_TABLE: 'CLOSE_TABLE',
    CLOSE_TABLE_SUCCESS: 'CLOSE_TABLE_SUCCESS',
    CLOSE_TABLE_ERROR: 'CLOSE_TABLE_ERROR',

    CONFIRM_TABLE: 'CONFIRM_TABLE',
    CONFIRM_TABLE_SUCCESS: 'CONFIRM_TABLE_SUCCESS',
    CONFIRM_TABLE_ERROR: 'CONFIRM_TABLE_ERROR',

    loadTables: () => ({
        type: actions.LOAD_TABLES,
    }),

    waiterLoadTables: () => ({
        type: actions.LOAD_TABLES,
    }),

    addTable: (data) => ({
        type: actions.ADD_TABLE,
        payload: data
    }),

    loadSingleTable: (data) => ({
        type: actions.LOAD_SINGLE_TABLE,
        payload: data
    }),

    processOrder: (data) => ({
        type: actions.PROCESS_ORDER,
        payload: data
    }),

    loadWaiterProducts: (data) => ({
        type: actions.LOAD_WAITER_PRODUCTS,
        payload: data
    }),

    addProductToTable: (data) => ({
        type: actions.ADD_PRODUCT_TO_TABLE,
        payload: data
    }),

    changeOrderQuantity: (data) => ({
        type: actions.CHANGE_ORDER_QUANTITY,
        payload: data
    }),

    cancelTable: (data) => ({
        type: actions.CANCEL_TABLE,
        payload: data
    }),

    closeTable: (data) => ({
        type: actions.CLOSE_TABLE,
        payload: data
    }),

    confirmTable: (data) => ({
        type: actions.CONFIRM_TABLE,
        payload: data
    })

};

export default actions;





