const actions = {

    GET_COMPANY_DATA: 'GET_COMPANY_DATA',
    GET_COMPANY_DATA_SUCCESS: 'GET_COMPANY_DATA_SUCCESS',
    GET_COMPANY_DATA_ERROR: 'GET_COMPANY_DATA_ERROR',

    UPDATE_COMPANY_DATA: 'UPDATE_COMPANY_DATA',
    UPDATE_COMPANY_DATA_SUCCESS: 'UPDATE_COMPANY_DATA_SUCCESS',
    UPDATE_COMPANY_DATA_ERROR: 'UPDATE_COMPANY_DATA_ERROR',

    GET_RESTAURANTS: 'GET_RESTAURANTS',
    GET_RESTAURANTS_SUCCESS: 'GET_RESTAURANTS_SUCCESS',
    GET_RESTAURANTS_ERROR: 'GET_RESTAURANTS_ERROR',

    GET_SINGLE_RESTAURANT_DATA: 'GET_SINGLE_RESTAURANT_DATA',
    GET_SINGLE_RESTAURANT_DATA_SUCCESS: 'GET_SINGLE_RESTAURANT_DATA_SUCCESS',
    GET_SINGLE_RESTAURANT_DATA_ERROR: 'GET_SINGLE_RESTAURANT_DATA_ERROR',

    UPDATE_RESTAURANT: 'UPDATE_RESTAURANT',
    UPDATE_RESTAURANT_SUCCESS: 'UPDATE_RESTAURANT_SUCCESS',
    UPDATE_RESTAURANT_ERROR: 'UPDATE_RESTAURANT_ERROR',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',

    LOGIN_AS: 'LOGIN_AS',
    LOGIN_AS_SUCCESS: 'LOGIN_AS_SUCCESS',
    LOGIN_AS_ERROR: 'LOGIN_AS_ERROR',

    getCompanyData: () => ({
        type: actions.GET_COMPANY_DATA,
    }),

    getRestaurants: () => ({
        type: actions.GET_RESTAURANTS,
    }),

    getSingleRestaurantData: () => ({
        type: actions.GET_SINGLE_RESTAURANT_DATA,
    }),

    updateRestaurant: () => ({
        type: actions.UPDATE_RESTAURANT,

    }),

    loginAs: (data) => ({
        type: actions.LOGIN_AS,
        payload: data
    }),

    updateCompanyData: (data) => ({
        type: actions.UPDATE_COMPANY_DATA,
        payload: data
    }),

    deleteUser: (data) => ({
        type: actions.DELETE_USER,
        payload: data
    }),

};

export default actions;





