import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '../../config/site.config'
import {clearLocalStorage, getRolesFromLocalStorage, getToken, isTokenExpired} from '@src/library/helpers/utility';
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import {createBrowserHistory} from 'history';
import {Roles} from "@src/library/helpers/authHelper";

const history = createBrowserHistory();


export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.login, data.payload, false)

        if (response) {

            if (response.roles[0] === Roles.SUPER_ADMIN) {
                yield put({
                    type: actions.SU_LOGIN_SUCCESS,
                    tenants: response.tenants,
                    token: response.access_token,
                    roles: response.roles.join(','),
                    id: response.id,
                    expires_in: response.expires_in,
                    username: response.username,
                    rememberMe: data.payload.rememberMe,
                })
            } else {

                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token: response.access_token,
                    roles: response.roles.join(','),
                    id: response.id,
                    expires_in: response.expires_in,
                    username: response.username,
                    rememberMe: data.payload.rememberMe,
                })
            }
            // }
        } else {
            yield put({
                type: actions.LOGIN_ERROR,
                payload: error
            })
        }
    })
}

export function* suLoginRequest() {
    yield takeEvery(actions.SU_TENANT_LOGIN_REQUEST, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.login, data.payload, false)

        if (response) {
            yield localStorage.removeItem('tenants')
            yield localStorage.setItem('tenantId', data.payload.tenantId)
            yield put({
                type: actions.SU_TENANT_LOGIN_SUCCESS,
                token: response.access_token,
                roles: response.roles.join(','),
                id: response.id,
                expires_in: response.expires_in,
                username: response.username,
                rememberMe: data.payload.rememberMe,
            })


        } else {
            yield put({
                type: actions.SU_TENANT_LOGIN_ERROR,
                payload: error
            })
        }
    })
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {

        yield localStorage.setItem('rememberMe', payload.rememberMe ?? 'false');
        yield localStorage.setItem('timestamp', new Date().getTime().toString());
        yield localStorage.setItem('expires_in', payload.expires_in);
        yield localStorage.setItem('id_token', payload.token);
        yield localStorage.setItem('roles', payload.roles);
        if (payload.username) {
            yield localStorage.setItem('initials', payload.username.length > 1 ? payload.username.substr(0, 2) : payload.username)
        }


    });
}

export function* suTenantLoginSuccess() {
    yield takeEvery(actions.SU_TENANT_LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem('rememberMe', payload.rememberMe ?? 'false');
        yield localStorage.setItem('timestamp', new Date().getTime().toString());
        yield localStorage.setItem('expires_in', payload.expires_in);
        yield localStorage.setItem('id_token', payload.token);
        yield localStorage.setItem('roles', payload.roles);
    });
}

export function* suLoginSuccess() {
    yield takeEvery(actions.SU_LOGIN_SUCCESS, function* (payload) {

        yield localStorage.setItem('rememberMe', payload.rememberMe ?? 'false');
        yield localStorage.setItem('timestamp', new Date().getTime().toString());
        yield localStorage.setItem('expires_in', payload.expires_in);
        yield localStorage.setItem('tenants', JSON.stringify(payload.tenants));
        yield localStorage.setItem('id_token', payload.token);
        yield localStorage.setItem('roles', payload.roles);
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        localStorage.clear();
    });
}

export function* checkAuthorization() {

    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        if (isTokenExpired()) {
            if (localStorage.length > 0 && localStorage.getItem('rememberMe') === 'true') {
                yield put({
                    type: actions.REFRESH_TOKEN
                });
            } else {
                clearLocalStorage()
            }
        } else {
            const token = getToken().get('idToken');

            if (token) {
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    token: localStorage.getItem("id_token"),
                    roles: localStorage.getItem("roles"),
                    expires_in: localStorage.getItem("expires_in"),
                    rememberMe: localStorage.getItem("rememberMe"),
                    initials: localStorage.getItem("initials")
                });
            }
        }
    });

}

export function* refreshToken() {

    yield takeEvery(actions.REFRESH_TOKEN, function* () {
        const {response, error} = yield call(SuperFetch.post, endpoints.refreshToken, null, false)
        if (response) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: response.access_token,
                roles: getRolesFromLocalStorage().get('roles'),
                profile: 'Profile',
                expires_in: response.expires_in,
                username: response.username,
                timestamp: new Date().getTime().toString(),
                rememberMe: localStorage.getItem('rememberMe'),
            });
        } else {
            localStorage.clear();
            history.push('/')
        }
    })
}

export function* signUp() {
    yield takeEvery(actions.SIGNUP_REQUEST, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.registration + endpoints.registerCompany, data.payload, false);

        if (response) {

            yield put({
                type: actions.SIGNUP_SUCCESS,
                payload: response
            });
        } else {

            yield put({
                type: actions.SIGNUP_ERROR,
                payload: error
            })
        }
    })
}

export function* recoverPassword() {
    yield takeEvery(actions.RECOVER_PASSWORD, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.registration + endpoints.resetPasswordByEmail, data.payload, false);

        if (response) {
            yield put({
                type: actions.RECOVER_PASSWORD_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.RECOVER_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* resetPassword() {
    yield takeEvery(actions.RESET_PASSWORD, function* (data) {

        const {response, error} = yield call(SuperFetch.post, endpoints.registration + endpoints.resetPassword, data.payload, false);

        if (response) {
            yield put({
                type: actions.RESET_PASSWORD_SUCCESS,
                payload: response
            });
        } else {
            yield put({
                type: actions.RESET_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
        fork(signUp),
        fork(refreshToken),
        fork(recoverPassword),
        fork(resetPassword),
        fork(suLoginSuccess),
        fork(suLoginRequest),
        fork(suTenantLoginSuccess)
    ]);
}
