import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import {useSelector} from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import {PUBLIC_ROUTE} from './route.constants';
import Loader from '@src/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@src/containers/Pages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: lazy(() => import('@src/containers/Pages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP,
        component: lazy(() => import('@src/containers/Pages/SignIn/SignUp'))
    },
    {
        path: PUBLIC_ROUTE.PASSWORD_RECOVERY,
        component: lazy(() => import('@src/containers/Pages/SignIn/PasswordRecovery'))
    },
    {
        path: PUBLIC_ROUTE.RESET_PASSWORD,
        component: lazy(() => import('@src/containers/Pages/SignIn/ResetPassword'))
    },
    {
        path: PUBLIC_ROUTE.SELECT_TENANT,
        component: lazy(() => import('@src/containers/Pages/SignIn/selectTenant')),
    },
];

function PrivateRoute({children, ...rest}) {
    const isLoggedIn = useSelector(state => state.Auth.idToken);
    return (

        <Route
            {...rest}
            render={({location}) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.component/>
                            </Route>
                        ))}

                        <PrivateRoute path="/dashboard">
                            <Dashboard/>
                        </PrivateRoute>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
