import actions from './actions';


const initState = {companyList: []};

export default function multiUserReducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_TENANT_INFO_SUCCESS:

            return {
                ...state,
                companyData: {
                    companyId: action.payload.value.companyId,
                    companyName: action.payload.value.companyName,
                    companyCity: action.payload.value.companyCity,
                    demo: action.payload.value.demo,
                    visible: action.payload.value.visible,
                }
            }
        case actions.SET_COMPANY_VISIBILITY_SUCCESS:
            return {
                ...state,
                companyData: {
                    companyId: action.payload.value.id,
                    companyName: action.payload.value.companyName,
                    companyCity: action.payload.value.companyCity,
                    demo: action.payload.value.demo,
                    visible: action.payload.value.visible,
                }
            }
        default:
            return state;
    }
}
